.csod-table-td {
    border-collapse: collapse;
    line-break: anywhere;
}

.csod-provider-name {
    font-weight: 600;
    color: #005BF0;
}

.csod-eca-container {
    margin: 4vw;
    font-family: 'Segoe UI';
}

.csod-eca-container td {
    font-size: 12px;

}

.csod-eca-container tr {
    margin: 16px 10px
}

.csod-eca-container table {
    padding: 16px 10px;
    ;
}

.select-all-courses {
    display: inline-flex;
    place-items: center;

}

.select-all-courses span {
    font-size: 15px;
}

.select-all-courses input {
    align-items: center;
    margin-top: 7px;
    margin-left:60px;
    border:2px solid black
}
.select-all-courses :hover{
    cursor: pointer;
}
.download-aicc{
    margin: 0 10px;
    width: auto !important;
}
.download-aicc:hover{
    cursor: pointer;
}
.download-retired{
    display: flex;
    margin-top: 5px;
}
.download-retired:hover{
    cursor: pointer;
}