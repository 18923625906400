  .csod-popup-background {
    position: fixed;
    top: -34px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(30,30,30,0.5);
    -webkit-animation: backgroundBlackout 0.3s;
    -moz-animation: backgroundBlackout 0.3s;
    animation: backgroundBlackout 0.3s;
    z-index: 10;
  }

  .csod-popup-container {
    max-width: 1200px;
    padding: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    background: white;
    -webkit-animation: popupAppearence 0.3s;
    -moz-animation: popupAppearence 0.3s;
    animation: popupAppearence 0.3s;
  }

  .csod-button-close {
    align-self: flex-end;
    padding: 15px;
    border: 0;
  }

  .csod-icon-success, .csod-icon-warning {
    padding: 36px;
  }
  
  .csod-icon-success {
    padding-bottom: 58px;
  }

  .csod-popup-title-text {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    margin: 0;
    font-size: 27px;
    font-weight: 600;
  }

  .csod-popup-desc-text {
    display: flex;
  }

  .csod-popup-desc-text, 
  .csod-popup-title-text, 
  .csod-popup-container > .csod-popup-inner-container > .csod-horizontal-align-container {
    padding-top: 30px;
  }

  .csod-popup-container > .csod-popup-inner-container > .csod-horizontal-align-container {
    padding-bottom: 50px;
  }

  .csod-provider-popup-nametitle {
    padding: 0 8px;
  }

  .csod-button-popup-done {
    padding: 18px 54px;
    font-size: 16px;
    align-self: center;
    border: 0;
  }

  .csod-popup-inner-container  {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4vh 9vw;
  }

  .csod-popup-inner-container.alternative-padding {
    padding: 3vh 2vw;
  }

  .csod-popup-errors-text-container {
    text-align: start;
    white-space: pre-wrap;
    overflow: auto;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 170px;
    padding: 10px;
  }

  .csod-popup-inner-container.alternative-padding > .csod-horizontal-align-container {
    padding: 15px !important;
  } 

  .csod-popup-inner-container > .csod-popup-inner-container {
    padding: 0;
  }

  .csod-popup-phase-condition-container {
    display: grid;
    max-width: initial;
    grid-template-columns: 230px 20px;
    text-align: left;
    margin: 5px 0;
    font-weight: 700;
  }

  .csod-popup-phases-outer-containder {
    margin: 10px 0;
  }

  @-webkit-keyframes popupAppearence {
    0% {
      transform: scale(0,0);
    }
    
    100% {
      transform: scale(1,1);
    }
  }

  @-moz-keyframes popupAppearence {
    0% {
      transform: scale(0,0);
    }
    
    100% {
      transform: scale(1,1);
    }
  }

@keyframes popupAppearence {
    0% {
        transform: scale(0,0);
    }

    100% {
        transform: scale(1,1);
    }
}

@-webkit-keyframes backgroundBlackout {
    0% {
        background-color: rgba(255,255,255,0);
    }

    100% {
    background-color: rgba(30,30,30,0.5);
    }
}

@-moz-keyframes backgroundBlackout {
    0% {
        background-color: rgba(255,255,255,0);
    }

    100% {
        background-color: rgba(30,30,30,0.5);
    }
}

@keyframes backgroundBlackout {
    0% {
        background-color: rgba(255,255,255,0);
    }

    100% {
      background-color: rgba(30,30,30,0.5);
    }
}