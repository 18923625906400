.container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.full-width{
  width: 100%;
}
/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
}

/* On mouse-over, add a gray background color */
.container:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 8px;
  top: 5px;
  width: 3px;
  height: 7px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.csod-framework-container {
  font-family: 'Segoe UI';
  margin: 0 10%;
}

.csod-framework-container>* {
  margin-top: 34px;
}

.csod-framework-tab-content,
.csod-current-tab-title {
  text-align: initial;
}

.csod-current-tab-title {
  font-weight: 600;
  font-size: 36px;
}

[class*="framework-header"] {
  font-weight: 600;
  font-size: 28px;
}

.irregularColor {
  color: red;
}

.changeByUserStyle {
  margin: 20px 0px;
  font-size: small;
  color: red;
}

.csod-tabs-container {
  box-shadow: 0px 20px 20px 10px white;
  display: flex;
  border-bottom: 1px solid #DEE2E6;
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;
  padding: 5px 6px 0px 36px;
  margin-left: -36px;
  margin-right: -36px;

}

.csod-tab-item {
  box-shadow: 0px -3px 2px 0px white;
  border-radius: 6px 6px 0 0;
  padding: 10px;
  padding-top: 11px;
  cursor: pointer;
  transition: box-shadow 0.3s;
  -webkit-transition: box-shadow 0.3s;
  -moz-transition: box-shadow 0.3s;
}

.csod-tab-item:hover {
  box-shadow: 0px -3px 2px 0px #DEE2E6;
}


.csod-tab-item.active-item {
  padding-top: 10px;
  margin-bottom: -1px;
  border-right: 1px solid #DEE2E6;
  border-left: 1px solid #DEE2E6;
  border-top: 1px solid #DEE2E6;
  border-bottom: 1px solid white;
}

.csod-input-title-text {
  font-weight: 600;
}

.csod-input-desc-text {
  font-weight: 400;
}

.csod-inputs-row-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.csod-inputs-column-container {
  display: flex;
  flex-direction: column;
}

.csod-inputs-column-container>* {
  margin-top: 8px;
}

.csod-inputs-column-container>*:first-child {
  margin-top: 0px;
}

.div-exceedwidth{
  display: grid;
  grid-template-columns: repeat(2, 100%);
}

#flashCopyButton {
  border: 2px solid transparent; /* Initial border color */
  transition: border-color 0.3s ease, transform 0.1s ease; /* Smooth transition */
}
.flash-change-button-anim {
  border-color: lightgreen; /* Color of border when clicked */
  transform: scale(1.5); /* Slightly scale the button to simulate press */
}
.input_ciid_wrapper {
  display: flex;
  align-items: center;
}

.input_ciid_wrapper button {
  height: 90%;
  margin-left: 10px;
}

.input_ciid_wrapper .csod-input-textbox {
  padding: 5px 0;
}


.input_ciid_wrapper .css-2b097c-container,
.input_ciid_wrapper .csod-input-textbox {
  width: 300px;
}

.csod-inputs-row-container>*,
.csod-container>.csod-input-title-text {
  align-self: center;
}

.csod-inputs-row-container>.csod-inputs-row-container>.csod-inputs-column-container {
  margin-right: 0px;
}

[class*="csod-inputs"]>[class*="csod-inputs"] {
  align-self: start;
  margin-right: 7%;
}

@media screen and (min-width: 1500px) {
  [class*="csod-inputs"]>[class*="csod-inputs"] {
    margin-right: 127px;
  }
}


[class*="csod-inputs"]>[class*="csod-inputs"]:last-child,
[class*="csod-inputs"]>[class*="csod-inputs"]:last-child:first-child {
  margin-right: 0px;
}

.csod-inputs-column-container>[class*="csod-inputs"] {
  width: 100%;
}

[class*="csod-inputs"]>[class*="csod-input-"] {
  margin-right: 37px;
}

.csod-input-textbox {
  padding: 6px 16px;
  border-radius: 3px;
  border: 1px solid #CED4DA;
}

[class*="-framework-layer"] {
  padding-bottom: 50px;
  box-shadow: inset 0px -1px 0px #E6E6E6;
  margin-top: 50px;
  transition: 0.3s;
}

.forceFullSyncCheckBox {
  margin: auto !important;
  margin-left: 16px !important;
}

[class*="-framework-layer"] .csod-inputs-column-container,
.margin-top-for-inputs {
  margin-top: 36px;
}

.csod-breadcrumb-container {
  margin-top: 32px;
  display: flex;
  padding: 12px 0px;
  background: #E9ECEF;
  border-radius: 4px;
}

.csod-breadcrumb-item {
  text-decoration: none;
  margin: 0px 20px;
  border-bottom: 1px solid #E9ECEF;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
}

.csod-breadcrumb-item:hover {
  border-bottom: 1px solid #005BF0;
}

.csod-inputs-container {
  display: flex;
  justify-content: space-between;
}

.csod-inputs-container .csod-button {
  font-family: "Segoe UI";
  font-size: 16px;
  width: fit-content;
  padding: 0px 20px;
  margin-left: 16px;
}

.csod-sub-framework-layer {
  margin: 65px -36px;
  padding: 1px 36px 43px 36px;
  ;
  background: #FAFAFA;
  border-radius: 9px;
}

/*Accordion*/
.csod-remove-row-button {
  position: absolute; 
  width: 20px;
  height: 20px;
  right: 0;
  top: 28px;
  border: 0;
  background: url(../../resources/csod-delete.svg) no-repeat;
}
.csod-remove-row-button-corp {
  position: absolute; 
  width: 20px;
  height: 20px;
  right: 0;
  top: 28px;
  border: 0;
  background: url(../../resources/csod-delete.svg) no-repeat;
}
button.org-remove-param{
  /* top: 57px !important; */
}

.csod-inputs-row-container:nth-child(2) .csod-remove-row-button {
  top: 56px !important; 
}

.csod-accordion-content .csod-button {
  font-family: "Segoe UI";
  font-size: 16px;
  margin-top: 15px;
  width: max-content;
  padding: 0 20px;
}

.csod-enable-toggle-for-accordion {
  position: absolute;
  top: -30px;
  right: 0;
}

.csod-accordion-framework-layer {
  position: relative;
}

.csod-accordion-framework-header {
  cursor: pointer;
  display: flex;
}

.csod-accordion-framework-layer.disabled .csod-accordion-framework-header:before {
  opacity: 0.2;
}

.csod-accordion-framework-header:before {
  content: "";
  padding: 10px;
  margin-right: 18px;
  background: url(../../resources/csod-accordion-arrow.svg) no-repeat;
  background-position-y: 50%;
  background-size: contain;
}

input.csod-accordion-chkbx:checked~.csod-accordion-framework-header:before {
  transform: rotate(180deg);
}

input.csod-accordion-chkbx {
  position: absolute !important;
  opacity: 0 !important;
}

.csod-accordion-content,
.csod-accordion-framework-header:before {
  transition: 0.3s;
}

input.csod-accordion-chkbx:checked~.csod-accordion-content {
  max-height: 200vh !important;
}

.csod-accordion-chkbx~.csod-accordion-content {
  max-height: 0;
}

.csod-accordion-content {
  padding: 1px;
  overflow: hidden;
}

/*Accordion end*/
 .choose-logo-button {
   text-align: center;
   width: 70px;
   max-height: 30px ;
   font-weight: 400;
   background: gainsboro;
   color: black;
   padding: 2px;
   border: 1px solid black;
   margin-bottom: 10px;
   border-radius: 6px;
   font-size: 11px;
   margin-right: 10px;
 }
.fileUploaderInfo{
  display: block;
  margin-right: 15px;
}
#imagePreview{
  height:75px;
  display: block;
  margin-top: 1px;
  margin-bottom: 1px;
  float: left;
margin-right: 10px;
}
.corp-div-selector {
  overflow: unset !important;
  padding: 10px;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  margin: 0px;
  margin-top: 20px;
}
.corpname-selector{
  margin-top: 10px !important;
  margin-right: 20px !important;
}
.logo-location {
  display: block;
}

.flex-wrapper {
  display:inline-block;
  vertical-align: top;
}
.file-input-buttons.left {
  float: left;
  margin-bottom: 10px
}
.file-input-buttons.left>.flex-wrapper>button {
  width: 250px;
}
#upload-logo-title{
  font-weight: bold;
}
.image-error{
  color:red;
  display: inline-block;
  width:100%;
}
