.csod-description-container:hover .csod-description-icon {
    background: url(../../../resources/csod-info-white.svg) no-repeat;
}

.csod-description-icon {
    background: url(../../../resources/csod-info.svg) no-repeat;
    height: 20px;
    width: 20px;
}

.csod-description-container {
    padding: 6px;
    transition: 0.3s;
    height: 20px;
    width: 20px;
    overflow: hidden;
    position: absolute;
    left: 120px;
    top: 26px;
    z-index: 1;
    color: white;
    font-size: 12px;
    max-height: 200px;
    max-width: fit-content;
}

.disabled .csod-description-container {
    opacity: 0;
}

.csod-description-text {
    white-space: pre-wrap;
    opacity: 0;
    min-width: max-content;
}

.csod-description-container:hover .csod-description-text {
    opacity: 1 !important;
}

.csod-description-container:hover {
    box-shadow: 0 0 3px black;
    background: rgba(30,30,30,0.8);
    border-radius: 3px;
    height: 1000px !important;
    width: 1000px !important;
}


.csod-copy-button {
    background: url(../../../resources/csod-copy-icon.svg) no-repeat;
    background-size: cover;
    padding: 10px;
    border: none;
    cursor: copy;
}


.csod-label-copied {
    color: #4DBD4D;
    box-shadow: 0px 0px 10px 0px #4DBD4D;
    transform: scale(1.03);
}