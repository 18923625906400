
.csod-providers-grid {
    table-layout: fixed;
    text-align: justify;
    font-size: 14px;
    width: 100%;
    border-spacing: initial;
}

.csod-search-container > * {
    margin-bottom: 85px;
}

.csod-providers-grid > tbody > tr:nth-child(2n+1) {
    background: #FAFAFA;
}

.csod-providers-grid > thead {
    box-shadow: inset 0px -1px 0px #E6E6E6;
}

.csod-search-container {
    display: flex;
    flex-direction: column;
    align-items: self-end;
}

/*Responsive implementation*/
@media screen and (max-width: 653px){
    .csod-search-form, .csod-search-add-new-container {
        flex-direction: column;
        width: 100%;
    }

    .csod-search-container > * {
        margin-bottom: 10px !important;
    }

    .csod-search-form > .csod-button, .csod-search-add-new-container > .csod-button {
        margin-top: 10px;
        margin-left: 0px !important;
    }

    .csod-providers-grid {
        word-wrap: break-word;
        border-spacing: 8px;
    }

    .csod-providers-grid thead {
        display: none;
    }

    .csod-providers-grid td {
        display:block;
		float:left;
		width:100%;
		clear:both;
		padding:10px 5px;
		-moz-box-sizing:border-box;
		-webkit-box-sizing:border-box;
		box-sizing:border-box;
    }

    .csod-providers-grid tr {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        background: white !important;
        box-shadow: 0 0 2px black;
        border-radius: 4.8px;
    }

    .csod-providers-grid tbody {
        display: block;
    }

    .csod-providers-grid td::before {
        content:attr(data-th);
		display:block;
		font-weight:bold;
		margin:0 0 2px;
		color:#000;
    }

    .csod-providers-grid tr tbody {
        float:left;
        width:100%;
        margin-bottom:10px;
    }
} 
/*Responsive implementation end*/

.csod-grid-header-container {
    display: flex;
    justify-content: space-between;
}

.csod-sorting-container {
    display: flex;
    color: #CED4DA;
    flex-direction: column;
}

.csod-sorting-container > * {
    border-radius: 20px;
    margin: -3px 1px;
    border: none;
    background: none;
    font-size: 12px;

}

[class*="csod-sort-"] {
    overflow: hidden;
    padding-top: 2px;
    cursor: pointer;
}

[class*="csod-sort-"]:hover {
    opacity: 0.5;
}

input::-webkit-calendar-picker-indicator {
    display: none;
}

.csod-providers-grid > thead > tr > th, .csod-providers-grid > tbody > tr > td, .csod-input-search-tb{
    word-break: break-word;
}

.csod-providers-grid > thead > tr > th, .csod-providers-grid > tbody > tr > td {
    padding: 16px 0px 16px 36px;
}

.sortable{
    color: black;
}

.csod-input-search-tb {
    border: 1px solid #CED4DA;
    min-height: 37px;
    padding-left: 36px;
}

.csod-search-add-new-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font: 20px 'Segoe UI';
    font-weight: normal;
}

.csod-search-form {
    display: flex;
    line-height: 100%;
}

.csod-search-form > .csod-button {
    margin-left: 18px;
}

.csod-search-form > .csod-button, .csod-search-add-new-container > .csod-button {
    width: fit-content;
    padding: 1px 18px;
    font-size: inherit;
    font-weight: normal;
    line-height: 210%;
    min-height: 41px;
    margin-left: 18px;
}

.csod-providers-count-title {
    font-size: 36px;
    font-weight: 600;
}

button.csod-search-tips-item {
    text-align: inherit;
    padding-top: 6px;
    padding-left: 30px;
    margin-top: -1px;
    border-top: 0px;
    background: white;
}

button.csod-search-tips-item:hover, .csod-search-tips-item:focus {
    background: dodgerblue;
    color: white;
}

.csod-providers-grid tbody>tr:hover {
    background: aliceblue !important;
    cursor: pointer;
}