.csod-input-dropdown {
    overflow: hidden;
    border: 1px solid #CED4DA;
    border-radius: 3px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
}
  
.csod-input-dropdown select {
    cursor: pointer;
    width: 100%;
    color: black;
    font-family: inherit;
    font-size: inherit;
    padding: 6px;
    padding-right: 34px;	
    border: 0;
    margin: 0;
    border-radius: 3px;
    -moz-appearance:none; /*hide default arrow in Firefox */
    -webkit-appearance:none; /*hide default arrow in Safari and Chrome */
    appearance:none;
}
  
.csod-input-dropdown::before {
    position: absolute;
    pointer-events: none;
    content: "___";
    color: white;
    border-left: 1px solid #CED4DA;
    padding: 0px 4px;
    top: 6.5px;
    right: 5px;
    font-size: 15px;
}

.csod-input-dropdown::after { /*  Custom dropdown arrow */
    position: absolute;
    pointer-events: none;
    content: "___";
    color: white;
    padding: 2px 4px;
    top: 4px;
    right: 3px;
    background:url("../../../resources/csod-chevron.svg") no-repeat;
    background-position-y: 60%;
    background-position-x: 50%;
    transition: 0.15s;
}
  
.csod-input-dropdown.active-item::after {
    transform: translateY(3px) rotate(180deg);
}

select:invalid {
    color: #CED4DA;
}

option {
    color: black;
}