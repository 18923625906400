.csod-providers-container {
    font-family: 'Segoe UI';
    margin: 6vw;
}

.csod-providers-grid {
    table-layout: fixed;
    text-align: justify;
    font-size: 15px;
    width: 100%;
    border-spacing: initial;
}

.low-org-select-margin{
    margin-bottom: 45px !important;
}

.csod-search-container > * {
    margin-bottom: 85px;
}

.csod-providers-grid > tbody > tr:nth-child(2n+1) {
    background: #FAFAFA;
}

.csod-providers-grid > thead {
    box-shadow: inset 0px -1px 0px #E6E6E6;
}

.csod-search-container {
    display: flex;
    flex-direction: column;
    align-items: self-end;
}

.sortable>div{
    color: black !important;
}


/*Responsive implementation*/
@media screen and (max-width: 653px){
    .csod-search-form, .csod-search-add-new-container {
        flex-direction: column;
        width: 100%;
    }

    .csod-search-container > * {
        margin-bottom: 10px !important;
    }

    .csod-search-form > .csod-button, .csod-search-add-new-container > .csod-button {
        margin-top: 10px;
        margin-left: 0px !important;
    }

    .csod-providers-grid {
        word-wrap: break-word;
        border-spacing: 8px;
    }

    .csod-providers-grid thead {
        display: none;
    }

    .csod-providers-grid td {
        display:block;
		float:left;
		width:100%;
		clear:both;
		padding:10px 5px;
		-moz-box-sizing:border-box;
		-webkit-box-sizing:border-box;
		box-sizing:border-box;
    }

    .csod-providers-grid tr {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        background: white !important;
        box-shadow: 0 0 2px black;
        border-radius: 4.8px;
    }

    .csod-providers-grid tbody {
        display: block;
    }

    .csod-providers-grid td::before {
        content:attr(data-th);
		display:block;
		font-weight:bold;
		margin:0 0 2px;
		color:#000;
    }

    .csod-providers-grid tr tbody {
        float:left;
        width:100%;
        margin-bottom:10px;
    }
} 
/*Responsive implementation end*/

.csod-grid-header-container {
    display: flex;
    justify-content: space-between;
}

.csod-sorting-container {
    display: flex;
    color: #CED4DA;
    flex-direction: column;
}

.csod-sorting-container > * {
    border-radius: 20px;
    margin: -3px 1px;
    border: none;
    background: none;
    font-size: 12px;

}

[class*="csod-sort-"] {
    overflow: hidden;
    padding-top: 2px;
    cursor: pointer;
}

[class*="csod-sort-"]:hover {
    opacity: 0.5;
}

input::-webkit-calendar-picker-indicator {
    display: none;
}

.csod-providers-grid > thead > tr > th, .csod-providers-grid > tbody > tr > td, .csod-input-search-tb{
    word-break: break-word;
}

.csod-providers-grid > thead > tr > th, .csod-providers-grid > tbody > tr > td {
    padding: 16px 0px 16px 36px;
}

.csod-input-search-tb {
    border: 1px solid #CED4DA;
    min-height: 37px;
    padding-left: 36px;
}

.csod-search-add-new-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font: 20px 'Segoe UI';
    font-weight: normal;
}

.csod-search-form {
    display: flex;
    line-height: 100%;
}

.csod-search-form > .csod-button {
    margin-left: 18px;
}

.csod-search-form > .csod-button, .csod-search-add-new-container > .csod-button {
    width: fit-content;
    padding: 1px 18px;
    font-size: inherit;
    font-weight: normal;
    line-height: 210%;
    min-height: 41px;
    margin-left: 18px;
}

.csod-providers-count-title {
    font-size: 36px;
    font-weight: 600;
}

button.csod-search-tips-item {
    text-align: inherit;
    padding-top: 6px;
    padding-left: 30px;
    margin-top: -1px;
    border-top: 0px;
    background: white;
}

button.csod-search-tips-item:hover, .csod-search-tips-item:focus {
    background: dodgerblue;
    color: white;
}

.csod-providers-grid tbody>tr:hover {
    background: aliceblue !important;
    cursor: pointer;
}
#fileUploader {
    display: none;
}

.file-input {
    background: gray;
    width: 100px;
    line-height: 250%;
    color: white;
    border: none;
    float: left;

}

.file-input-buttons>button {
    float: left;
}

#filter-selected-wrapper {
    float: left;
    padding-top: 8px;
}

#filter-selected-wrapper>span:hover {
    cursor: default;
}

#filter-selected-wrapper>input:hover {
    cursor: pointer;
}

.csod-bundle-numbers {
    font-size: 17px;
    text-align: left;
    font-weight: 100;
}

.advanced-search-buttons>input, .react-datepicker-wrapper {
    margin-right: 10px;
    height: 10px !important;
    display: inline-block;
    font-size: 10px;
    text-align: left;
    padding: 0px 10px;
    font-weight: bold;
    color: black;
}

    
    
    .advanced-search-wrapper,
    .advanced-search-wrapper>form {
        width: 100%;
        text-align: left;
    }
    
    .advanced-search-buttons>.csod-input-search-tb {
        float: left
    }
    
    .advanced-search-wrapper  .select-class {
        max-width: 500px;
    
    }
    
    .advanced-search-wrapper {
        margin-bottom: 20px !important;
    }
    
    input[type="date"] {
        min-width: 95.6%;
        -webkit-appearance: none;
        min-height: calc(var(--lwc-heightInput, 1.875rem) + (1px * 2) + 24px) !important;
    }
    
    .advanced-search-wrapper .select-input, .select-input-org {
        float: left;
        width:200px !important;
        margin-right: 15px;
    }
    
    .advanced-search-wrapper .csod-inputs-column-container.date {
        position: relative;
        top: -22px;
        width: 170px;
        float: left;
        margin-left: 3px;
        margin-right: 15px;
    }
    
    .advanced-search-wrapper .date>input,
    .advanced-search-wrapper input.csod-input-textbox {
        width: 100px;
        margin: 0px;
        padding: 0;
        min-height: 37px !important;
        padding-left: 10px;
        border-radius: 5px;
    }
    
    .clear-button {
        color: black !important;
        width: 100% !important;
        background: white;
        border: 1px solid lightgrey !important;
    }

   
    .courses-selected-text {
        text-align: left;
    }
    
    .file-input-label {
        text-align: left;
    }
    
    .select-all-courses input {
        margin: 0 0 0 0 !important;
        border: none;
    }
    
    .select-all-courses-th {
        padding-left: 5px !important;
        width:60px;
    }
    
    .fileUploaderInfo {
        float: left;
        margin-top: 6px;
    }
    
    .upload-button-wrapper {
        width: 100%;
        display: block;
        ;
        float: left
    }
    
    .upload-button-wrapper button {
        float: left;
        margin-top: 20px;
        ;
    }

    .upload-file-container{
        display: flex;
        align-items: baseline;
        
    }
    /* .upload-file-container > div > form
    {margin-right: 250px;} */


    td.csod-table-td{
        font-weight: 600;
    }

    .download-aicc{
        position: relative;
        top:20px
    }


    .clear-button-wrapper {
        display: inline-block;
    }
    .wrapper-top{
        margin-top: 20px;
    }

    .max-width-search{
        width: 100%;
        display: inline-flex;
        margin-right: 10%;
    }

    .select-input-org{
        margin-top:20px;
    }

    .flatpickr-input{
        border: 1px solid lightgrey;
        border-radius: 4.8px;
        padding: 8.5px;
    }
    .refinement-div{
        background-color: rgba(201, 201, 201, 0.152);
        padding: 10px;
    }

    .red.download-aicc{
        background: rgba(210, 77, 77, 0.687);
    }
    .custom-label-style{
        display: inline-block;
        padding: 8.5px 12px;
        cursor: pointer;
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius:4px;
        position: relative;
        border-radius: 5px;
        font-weight: 600;
        font-size: small;
        vertical-align: top;
    }
    .custom-label-style:hover{
        text-decoration: none;
        background-color: rgba(0, 0, 0, 0.04);
    }
    .custom-label-style:focus{
        text-decoration: none;
        background-color: rgba(0, 0, 0, 0.04);
    }

    input[type="file"].hide-input {
        display: none;
    }

    .allView{
        border-color: black !important;
        color: black !important;
    }
    .selectedView{
        border-color: #0075ff !important;
        color: #0075ff !important;
    }

    .marginTop{
        margin-top:10px;
    }

    .client-facing-catalog > * {
        font-family: 'Roboto';
        font-weight: 500;
        line-height: 1.75;
        letter-spacing: 0.02857em;
        font-size: 0.875rem;
    }
    
    .extraPadding > input{
        padding:8px;
    }

    .MuiButtonBase-root{
        padding: 6px !important
    }
    .flatpickr-input[readonly]{
        padding: 10px;
    }

    .csod-search-field-tips-container > input{
        font-size: 0.875rem;
    }