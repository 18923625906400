.csod-lds {
    display: inline-block;
    width: 368px;
    height: 368px;
  }
  .csod-lds::before {
    content: " ";
    display: block;
    width: 360px;
    height: 360px;
    margin: 8px;
    background: url("../../resources/csod-icon.png") no-repeat;
    background-size: contain;
    animation: csod-lds 1.2s alternate infinite;
  }
  /* @keyframes csod-lds {
    0% {
      opacity: 0.8;
      transform: scale(1.0);
    }
    100% {
      opacity: 1;
      transform: scale(1.1);
    }
  } */
  @keyframes csod-lds {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}
