textarea.csod-input-large-textbox {
    resize: none;
}

.csod-input-large-textbox-container {
    min-height: 88px;
}

.csod-input-large-textbox {
    padding: 6px;
    font-family: inherit;
    font-size: inherit;
    border-color: #CED4DA;
    border-radius: 3px;
    min-width: 100%;
    min-width: -webkit-fill-available;
    min-height: 88px;
}

.csod-input-largebox-letter-counter {
    color: #CED4DA;
    margin-top: 0px !important;
    text-align: end;
}