@media screen and (max-height: 800px){
    .csod-auth-inputs-container {
        margin: 5px !important;
    }

    .csod-text-box-container {
        margin-top: 5px !important;
    }
} 

.csod-auth-container > .csod-app-logo{
    width: 100%;
    min-height: 36px;
}

.csod-auth-components-container{
    width: 100vw;
    height: 100vh;
    text-align: center;
    height: 100vh;
    display: flex;
    justify-content: center;
}

.csod-auth-error-message {
    color: #F26178;
}

.csod-button > .lds-dual-ring {
    padding: 1px;
    width: auto;
    height: 100%;
}

.csod-button > .lds-dual-ring::after {
    width: 22px;
    height: 22px;
    margin: 4px;
    border-width: 4px;
}

.csod-auth-container {
    min-width: 320px;
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: 0.3s;
}

.csod-outer-auth-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.csod-auth-inputs-container{
    width: 70%;
    margin: 34px;
}

.login {
    width: 50% !important;
}
.send-verification{
    width:100% !important;
    margin-bottom:20px;
}

.m10{
    margin:10px;
}