.csod-copy-button {
    background: url(../../../resources/csod-copy-icon.svg) no-repeat;
    background-size: cover;
    padding: 10px;
    border: none;
    cursor: copy;
}

.csod-input-label {
    word-wrap: break-word;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    padding: 6px;
    margin-left: -6px;
    border-radius: 6px;
    box-shadow: 0px 0px 6px 0px transparent;
}

.csod-label-copied {
    color: #4DBD4D;
    box-shadow: 0px 0px 10px 0px #4DBD4D;
    transform: scale(1.03);
}