ul.pagination {
    margin-top: 16px;
    padding: 0;
    text-align: center;
    list-style: none;
    font-size: 0;
    position: sticky;
    bottom: 5px;
}

ul.pagination li {
    list-style-type: none;
    display: inline-block;
    font-family: sans-serif;
    font-size: 16px;
    background: white;
    transition: 0.3s;
    border: 1px solid #DEE2E6;
    color:  #007BFF;
}

ul.pagination li.active, ul.pagination li:active{
    background: #007BFF;
    color: white;
}

ul.pagination li:nth-child(n+2) {
    margin-left: -1px;
}

ul.pagination li:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

ul.pagination li:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

ul.pagination li:hover {
    background: aliceblue;
}

ul.pagination li.active:hover {
    background: rgb(0, 102, 255);
    color: white;
}

.page-item > a {
    user-select: none;
}

.page-link {
    border: none;
    background: none;
    padding: 8px 16px;
    color: inherit;
}