body{
  background: url(./resources/csc_roger_bg_r1.svg);
  background-size: cover;
}

.App {
  background: white;
  text-align: center;
  height: fit-content;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.csod-app-logo {
  background: url(./resources/csod-logo-white1.svg) no-repeat;
  background-size: contain;
  background-position-x: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

/*Containers*/
.csod-text-box-container {
  height: 41px;
  background: #FFFFFF;
  width: 100%;
  margin-top: 16px;
  color: #A6A6A6;
  text-align: left;
  padding-left: 10px;
  font-size: 16px;
  transition: 0.3s;
}

.csod-text-box-container:focus-within{
  box-shadow: inset 0 0 2px 0px blue;
}
/*end containers*/

.csod-remind-text {
  max-width: 320px;
  height: 20vh;
  font-family: 'Segoe UI', Roboto;
  color: white;
  font-size: 16px;
  display: flex;
}

.csod-remind-text span{
  align-self: flex-end;
}

/*Button*/
.csod-button {
  
  height: 41px;
  color: white;
  line-height: 250%;
  width: 20%;
  border: none;
}

[class *="-button"] {
  transition: 0.05s;
  -moz-transition: 0.05s;
  -webkit-transition: 0.05s;
  cursor: pointer;
}

[class *="-button"]:hover, [class *="-button"]:focus {
  opacity: 0.9;
}

[class *="csod-button"]:active{
  box-shadow: inset 0 0 4px 0px black;
}
/*End button*/

/*Color helpers*/
.crimson{
  background: #F26178;
}
.smallpin{
  font-size: 10px;
}
.blue{
  background: #005BF0;
}
.invisible{
  display:none;
}
.gray {
  background: #6C757D;
}

.light-gray {
  background: #E9ECEF;
}

.white-text {
  color: white;
}

.blue-text {
  color: #005BF0;
}

.gray-text {
  color: #6C757D;
}

.dark-gray-text {
  color: darkgray;
}

.green-text {
  color: #4DBD4D;
}

.light-gray-text {
  color: #CED4DA;
}

.error-text-color {
  color: #DC3545 !important;
}

.error-border {
  border-color: #DC3545 !important;
}
/*Color helpers*/

.csod-header-text-1{
  color: white;
  font-size: 28px;
  margin-top: 12px;
}

input{
  border: none;
  font-family: inherit;
  font-size: inherit;
}

.csod-text-box {
  width: calc(100% - 44px);
  margin-left: 12px;
  margin-top: 10px;
  display: inline-block;
  vertical-align: middle;
}

/*Global Loader*/
.csod-horizontal-align-container > .lds-dual-ring::after {
  border-color: gray transparent gray transparent;
}

.global-loader-container {
  position: absolute;
  background: white;
  width: 100vw;
}
/*Global Loader end*/

/*Style helpers*/
.border-for-input {
  border-radius: 3px;
  border: 1px solid #CED4DA;
}

.less-margin-top {
  margin-top: 18px !important;
}

.csod-inputs-sticky-container {
  margin-top: 0 !important;
  position: sticky;
  bottom: 0;
  background: white;
  padding-bottom: 15px;
  margin: 0 -36px;
}

.csod-inputs-sticky-container > * {
  border-top: 1px solid #E6E6E6;
  margin-top: -3px;
  padding-top: 36px;
  padding-bottom: 20px;
}

.margin-right-12 {
  margin-right: 12px;
}

.margin-right-neg-12 {
  margin-right: -12px;
}

.margin-left-right-64 {
  margin: 0px 64px;
}

.margin-top-64 {
  margin-top: 64px !important;
}

.no-margin-top {
  margin-top: 0px !important;
}

.min-w-min-h-300-600 {
  min-height: 200px !important;
  min-width: 600px !important;
}

.padding-top-0 {
  padding-top: 0 !important;
}

.min-width-20 {
  min-width: 20px;
}

.middle-width {
  max-width: 564px;
}

.small-width {
  max-width: 325px;
}

.smallest-width {
  max-width: 238px;
}

.inherit-width {
  width: inherit;
}

.large-width, .maximum-width {
  width: 100%;
}

.maximum-width > * {
  margin-right: 0px !important;
}

[class*="csod-input-"] {
  transition: 0.3s;
  -moz-transition: 0.3s;
  -webkit-transition: 0.3s;
  box-shadow: 0 0 3px transparent;
}

[class*="csod-input-"].alternative-margin-for-inputs {
  margin-right: 10px !important;
}

[class*="csod-input-"]:focus,
[class*="csod-input-"]:focus-within:not([class*="container"]), 
.csod-input-hour-container:focus-within {
  box-shadow: 0 0 3px #005BF0;
}

[class*="csod-input-"]:focus.error-border,
[class*="csod-input-"]:focus-within:not([class*="container"]).error-border, 
.csod-input-hour-container:focus-within.error-border {
  box-shadow: 0 0 3px #DC3545;
}

.two-columns-container > *{
  width: 50%;
}

.three-columns-container > *{
  width: 33.33%;
}

.four-columns-container > *{
  width: 25%;
}

.five-columns-container > *{
  width: 20%;
}

.two-columns-100-width-elements-container > * {
  width: 100%;
}

.fit-content-width-elements-container > * {
  width: fit-content;
}

.four-columns-30-20-20-30 > *:nth-child(1), .four-columns-30-20-20-30 > *:nth-child(4) {
  width: 30%;
}

.four-columns-30-20-20-30 > *:nth-child(2), .four-columns-30-20-20-30 > *:nth-child(3) {
  width: 20%;
}

.no-right-margins[class*="csod-inputs"] > [class*="csod-inputs"] {
  margin-right: 0;
}

.csod-vertical-align-container {
  display: flex;
  height: 100vh;
  justify-content: center;
  flex-direction: column;
}

.csod-horizontal-align-container {
  display: flex;
  justify-content: center;
}

.non-selectable {
  user-select: none; 
   -webkit-user-select: none; 
   -khtml-user-select: none; 
   -moz-user-select: none; 
   -ms-user-select: none;
}

.hidden-item {
  visibility: hidden;
}

.hard-rounded-corners {
  border-radius: 25px;
}

.rounded-corners {
  border-radius: 13px;
}

.less-rounded-corners {
  border-radius: 4.8px;
}

.disabled {
  pointer-events: none;
  color: #CED4DA !important;
}


::placeholder {
  color:  #CED4DA;
}

.no-flex-wrap {
  flex-wrap: nowrap !important;
}
/*End style helpers*/

/*Icons*/
.csod-icon-warning {
  background: url(./resources/csod-warning-icon.svg) no-repeat;
  background-size: contain;
}

.csod-icon-success {
  background: url(./resources/csod-gray-checkmark.svg) no-repeat;
  background-size: contain;
}

.csod-button-close {
  background: url(./resources/csod-gray-cross.svg) no-repeat;
  background-position-x: 50%;
  background-size: contain;
}
/*Icons end*/


/*free form style fix start */
div.key{
  text-align: left !important;
  background-color: hsl(0, 0%, 90%) !important;
  color: black !important;
  font-weight: 100 !important;
}

/*free form style end*/