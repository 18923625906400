.csod-table-td {
    border-collapse: collapse;
    line-break: anywhere;
    font-size: 12px;
    font-family: 'Segoe UI';
}

.csod-package-name {
    font-weight: 600;
    color: #005BF0;
}