.csod-errors-grid-column-container {
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow: auto;
}

.csod-errors-grid-column-container-expandable {
    display: flex;
    flex-direction: column;
}

.csod-errors-grid-enhanced-content.active .csod-errors-grid-item:first-child {
    padding-left: 20px;
}

.csod-errors-grid-enhanced-content {
    overflow: hidden;
    height: 0px;
}

.csod-errors-grid-enhanced-content.active {
    height: fit-content;
}

.csod-white-space {
    opacity: 0;
}

.csod-errors-grid-item {
    display: flex;
    align-items: center;
}

.csod-errors-grid-column-container-expandable:nth-child(2n+1) {
    background: whitesmoke;
}

.csod-errors-grid-row-container {
    display: flex;
}

.csod-errors-grid-row-container>* {
    width: 100%;
    padding: 5px;
}

.csod-expand-tree-grid-checkmark {
    border: 0;
    background: transparent;
    font-size: 20px;
    color: gray;
    font-weight: 800;
}