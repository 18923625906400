.csod-header-container {
    background: #0047BA;
    width: 100%;
    display: flex;
    flex-direction: row;
    height: fit-content;
    min-height: 42px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1020;
    width: 100%;
    transition: top 0.2s ease-in-out 0s, bottom 0.2s ease-in-out 0s;
    background-color: #1a202c;
    font-family: "Segoe UI", Arial, Helvetica, sans-serif !important;
}

.csod-header-user-inputs-container {
    width: calc(100% - 134px);
    display: flex;
    justify-content: flex-end;
    color: white;
}

.csod-header-container > .csod-app-logo {
    margin: 8px;
    /* height: 35px; */
    width: 200px;
}

.csod-header-user-inputs-container > * {
    line-height: 200%;
    margin: 5px;
    font-family: 'Segoe UI';
}

.csod-logout {
    text-decoration: none;
    color: white;
    font-weight: bold;
}

.csod-nav-text:hover,
.csod-logout:hover {
    text-decoration: underline;
    cursor:pointer;
}

.csod-username {
    font-weight: 100;
}

.csod-nav-link{
   margin-right:auto;
}
.csod-nav-text{
    text-decoration: none;
    font-weight: 600;
    font-size: unset;
    display: inline-block;
    margin: 0px 20px;
}