body {
  margin: 0;
  font-family: Comfortaa;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:focus{
  outline: none !important;
}

@font-face {
  font-family: 'Comfortaa';
  src: local('Comfortaa'), url(./fonts/comfortaa/comfortaa.ttf) format('truetype');
}