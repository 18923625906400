.csod-input-radio-button-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0px;
    font-weight: 600;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .csod-radio-display-container{
    display:flex;
  }

  /* Hide the browser's default radio button */
  .csod-input-radio-button-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom radio button */
  .radio-button-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .csod-input-radio-button-container:hover input ~ .radio-button-checkmark {
    background-color: #ccc;
  }
  
  /* When the radio button is checked, add a blue background */
  .csod-input-radio-button-container input:checked ~ .radio-button-checkmark {
    background-color: #2196F3;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .radio-button-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .csod-input-radio-button-container input:checked ~ .radio-button-checkmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .csod-input-radio-button-container .radio-button-checkmark:after {
    top: 6.5px;
    left: 6.5px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: white;
  }
  .radio-button-checkmark {
    top: calc(50% - 10px);
  }

  .csod-edit-button {
    background: url(../../../resources/csod-edit.svg) no-repeat;
    background-size: cover;
    padding: 10px;
    border: none;
    cursor: pointer;
    position: relative;
    left: -26px;
  }